import { FRAGMENTS } from '../../../../models/table-of-contents/constants';
import useTableOfContents from '../../../../cms/utils/use-table-of-contents';

const useNavigationPerks = ({ skip }: { skip: boolean } = { skip: false }) =>
  useTableOfContents({
    fragment: FRAGMENTS.PERKS,
    skip,
    slug: 'perks',
  });

export default useNavigationPerks;
