import { List, P, Small, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    description: (
      <>
        <P>All the gold category services. All solid gold. Get the widest range of treatments, including:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Back, neck and spine</Strong>
          </li>
          <li>
            <Strong>Cataracts</Strong>
          </li>
          <li>
            <Strong>Heart and vascular system</Strong>
          </li>
          <li>
            <Strong>Joint replacements</Strong>
          </li>
        </List>
        <P>
          It’s the gold standard for your ongoing peace of mind. Planning on having kids too? Get the reassurance of
          having access to <Strong>pregnancy and birth services</Strong>.
        </P>
        <P>
          You also get <Strong>midwife assisted home birth*, disease management appliances</Strong> and{' '}
          <Strong>unlimited medically necessary ambulance^</Strong> and no excess for dependants to help you stay gold.
        </P>
      </>
    ),
    disclaimer: (
      <>
        <Small>*where a Medicare rebate is not payable.</Small>
        <br />
        {ambulanceDisclaimerTasQld}
      </>
    ),
  },
};
