import { DATE_FORMAT, LEAD_SOURCE_TYPES } from '@ahmdigital/constants';
import { flow, get, includes, pick, set, values } from 'lodash/fp';
import moment from 'moment';

import { CREATE_LEADS } from './query';
import { LeadInput } from '../../types/leads';
import analytics from '../../analytics';
import apolloClient from '../../graphql/client';
import browserUtils from '../browser';
import getAnalyticsClientId from '../../../../utils/get-analytics-client-id';
import LEAD_CAPTURE_FIELDS from './constants';
import logging from '../../logging';

const BUY_FORM_LEADS = [LEAD_SOURCE_TYPES.ABANDONED_CART, LEAD_SOURCE_TYPES.ABANDONED_CART_PRE_BUY_FORM];
const leadTypeValues = values(LEAD_SOURCE_TYPES);

// NOTE: Keeping this logic on the front-end as it makes it easier to A/B test and keeps our API clean
const sharedLeadFields = [
  LEAD_CAPTURE_FIELDS.AGE,
  LEAD_CAPTURE_FIELDS.EMAIL,
  LEAD_CAPTURE_FIELDS.EXCESS,
  LEAD_CAPTURE_FIELDS.FREQUENCY,
  LEAD_CAPTURE_FIELDS.INCOME,
  LEAD_CAPTURE_FIELDS.INCOME_BRACKET,
  LEAD_CAPTURE_FIELDS.INCOME_TYPE,
  LEAD_CAPTURE_FIELDS.INCOME_TIER,
  LEAD_CAPTURE_FIELDS.MARKETING_OPT_IN,
  LEAD_CAPTURE_FIELDS.NAME,
  LEAD_CAPTURE_FIELDS.PARTNER_AGE,
  LEAD_CAPTURE_FIELDS.PRICE,
  LEAD_CAPTURE_FIELDS.PRODUCT,
  LEAD_CAPTURE_FIELDS.PRODUCT_TYPE,
  LEAD_CAPTURE_FIELDS.REBATE_AGE_BRACKET,
  LEAD_CAPTURE_FIELDS.REBATE_AGE_TIER,
  LEAD_CAPTURE_FIELDS.REBATE_PERCENTAGE,
  LEAD_CAPTURE_FIELDS.SCALE,
  LEAD_CAPTURE_FIELDS.STATE,
  LEAD_CAPTURE_FIELDS.URL,
  LEAD_CAPTURE_FIELDS.YOUTH_DISCOUNT_PERCENTAGE,
];

const leadFieldsByLeadType: { [key: string]: string[] } = {
  [LEAD_SOURCE_TYPES.ABANDONED_CART]: [
    ...sharedLeadFields,
    LEAD_CAPTURE_FIELDS.DATE_OF_BIRTH,
    LEAD_CAPTURE_FIELDS.HAS_CASH_ASSIST_INTEREST,
    LEAD_CAPTURE_FIELDS.PARTNER_DOB,
    LEAD_CAPTURE_FIELDS.PHONE,
  ],
  [LEAD_SOURCE_TYPES.ABANDONED_CART_PRE_BUY_FORM]: sharedLeadFields,
  [LEAD_SOURCE_TYPES.REQUEST_A_CALL]: [
    ...sharedLeadFields,
    LEAD_CAPTURE_FIELDS.DATE_OF_BIRTH,
    LEAD_CAPTURE_FIELDS.IS_MEMBER,
    LEAD_CAPTURE_FIELDS.PARTNER_DOB,
    LEAD_CAPTURE_FIELDS.PHONE,
  ],
  [LEAD_SOURCE_TYPES.SAVE_AND_RETRIEVE_QUOTE]: [
    ...sharedLeadFields,
    LEAD_CAPTURE_FIELDS.IS_MEMBER,
    LEAD_CAPTURE_FIELDS.PHONE,
  ],
};

const BUY_FORM_LEAD = 'buy_form_lead';

const createLead = async ({ leadInput, sourceType }: { leadInput: LeadInput; sourceType: string }) => {
  const isValidLeadType = includes(sourceType, leadTypeValues);
  if (!isValidLeadType) {
    throw new Error('Invalid source lead type');
  }

  const isBuyFormLead = includes(sourceType, BUY_FORM_LEADS);
  let existingLeadId = null;
  if (isBuyFormLead) {
    const { sessionStorage } = browserUtils.getWindow() as any;
    const existingLeadData = JSON.parse(sessionStorage.getItem(BUY_FORM_LEAD));
    if (existingLeadData) {
      const { leadCreatedTime, leadId, leadCreatedSourceType } = existingLeadData;
      const now = moment();
      const leadCreatedPlus5Minutes = moment(leadCreatedTime, DATE_FORMAT.ISO_8601_WITH_TIME).add(5, 'minutes');
      // Remove the lead data if it older than 5 minutes
      if (now.isAfter(leadCreatedPlus5Minutes)) {
        sessionStorage.removeItem(BUY_FORM_LEAD);
      } else if (
        leadCreatedSourceType === LEAD_SOURCE_TYPES.ABANDONED_CART ||
        (leadCreatedSourceType === LEAD_SOURCE_TYPES.ABANDONED_CART_PRE_BUY_FORM &&
          sourceType === LEAD_SOURCE_TYPES.ABANDONED_CART_PRE_BUY_FORM)
      ) {
        // If the abandoned cart lead has been created within the last 5 minutes, then
        // this lead will get updated rather than a new lead being created.
        // Likewise, if it was just the pre buy form lead that was created and the
        // pre buy form is being captured again, then update the lead
        existingLeadId = leadId;
      }
    }
  }

  const leadWithSource = flow(
    pick(leadFieldsByLeadType[sourceType]!),
    set('source', sourceType),
    set('leadId', existingLeadId),
    set('gaClientId', await getAnalyticsClientId()),
  )(leadInput);

  try {
    const lead = await apolloClient.mutate({
      mutation: CREATE_LEADS,
      variables: { input: leadWithSource },
    });

    analytics.publish('action', {
      action: 'submitted',
      component: `LeadCapture - ${sourceType}`,
    });

    const leadId = get('data.createLead.id', lead);
    // Store lead id to facilitate lead capture updates and to help remove
    // duplicate lead creations
    if (isBuyFormLead && !existingLeadId) {
      const leadData = {
        leadCreatedSourceType: sourceType,
        leadCreatedTime: moment().format(DATE_FORMAT.ISO_8601_WITH_TIME),
        leadId,
      };
      sessionStorage.setItem(BUY_FORM_LEAD, JSON.stringify(leadData));
    }
    return leadId;
  } catch (error) {
    logging.getLogger().error(`Lead creation failed: ${sourceType}`, {
      error,
    });
    if (!isBuyFormLead) {
      throw error;
    }
    // if the lead is on the buy form, we don't want to throw an error and stop the join request
    return null;
  }
};

export default createLead;
