import Head from 'next/head';
import React from 'react';

const FAVICON_BASE_PATH = '/assets/favicon/';

const FaviconHeader = () => (
  <Head>
    <link rel="apple-touch-icon" href={`${FAVICON_BASE_PATH}apple-touch-icon.png`} />
    <link rel="apple-touch-icon" sizes="120x120" href={`${FAVICON_BASE_PATH}apple-touch-icon-120x120.png`} />

    <link rel="icon" type="image/png" sizes="16x16" href={`${FAVICON_BASE_PATH}favicon-16x16.png`} />
    <link rel="icon" type="image/png" sizes="32x32" href={`${FAVICON_BASE_PATH}favicon-32x32.png`} />
    <link rel="icon" type="image/png" sizes="192x192" href={`${FAVICON_BASE_PATH}android-chrome-192x192.png`} />
    <link rel="icon" type="image/png" sizes="256x256" href={`${FAVICON_BASE_PATH}android-chrome-256x256.png`} />

    <link rel="mask-icon" href="safari-pinned-tab.svg" color="black" />

    <link rel="icon" href={`${FAVICON_BASE_PATH}favicon.ico`} />
    <link rel="manifest" href={`${FAVICON_BASE_PATH}manifest.json`} />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="mobile-web-app-capable" content="yes" />

    <meta name="msapplication-square70x70logo" content={`${FAVICON_BASE_PATH}mstile-70x70.png`} />
    <meta name="msapplication-square150x150logo" content={`${FAVICON_BASE_PATH}mstile-150x150.png`} />
    <meta name="msapplication-square310x310logo" content={`${FAVICON_BASE_PATH}mstile-310x310.png`} />
    <meta name="msapplication-TileImage" content={`${FAVICON_BASE_PATH}mstile-144x144.png`} />
  </Head>
);

export default FaviconHeader;
