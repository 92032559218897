import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../../ahm-constants';

export default {
  description: (
    <P>
      Plus you get <Strong>70%</Strong> back on lots of extras for your head to your toes, from <Strong>dental</Strong>{' '}
      all the way down to <Strong>podiatry</Strong> plus many, many more.
    </P>
  ),
  getSellingPoints: () => [
    {
      description: 'up to your annual limit.',
      icon: 'black-70',
      title: '70% back on included extras',
    },
    {
      description: 'Frames, prescription lenses and contact lenses included.',
      icon: 'optical',
      title: '100% back on optical',
    },
  ],
  spotlight: {
    description: '70% back',
    icon: constants.PRODUCT.BLACK_70_EXTRAS,
  },
  upgrade: {
    cta: 'Get the best dental limits we offer with super extras',
    id: constants.PRODUCT.SUPER_EXTRAS,
    title: '',
  },
  upsell: {
    description: 'Add hospital cover to your extras and you’ll also add peace of mind.',
    header: 'Make a nasty surprise less nasty',
  },
};
