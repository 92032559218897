import getAppDate from '@ahmdigital/logic/lib/utils/get-app-date';
import getSaleableProductIdsKeyedByTypeLib from '@ahmdigital/logic/lib/utils/get-saleable-product-ids-keyed-by-type';

import config from '../../config';

/**
 * A small wrapper utility to save importing config and getAppDate everywhere.
 */
const getSaleableProductIdsKeyedByType = () => {
  const appDate = getAppDate(config);
  return getSaleableProductIdsKeyedByTypeLib(appDate);
};

export default getSaleableProductIdsKeyedByType;
