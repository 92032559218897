import { createSelector } from 'reselect';
import { flow, get, keyBy, mapValues } from 'lodash/fp';

import calculateAgeFromDateOfBirth from '../../utils/calculate-age-from-date-of-birth';
import selectCustomer from '../select-customer';

const getCustomerAges = flow(
  get('members'),
  keyBy('relationship'),
  mapValues('dob'),
  mapValues(calculateAgeFromDateOfBirth),
);

export default createSelector(selectCustomer, getCustomerAges);

export type MemberYouthDiscountAgesType = Record<string, number>;

export { getCustomerAges };
