import { createSelector } from 'reselect';

import getYouthDiscountPercentageFromAges from '../../utils/get-youth-discount-percentage-from-ages';
import selectYouthDiscountAges from '../select-youth-discount-ages';

const selectCalculatedYouthDiscountPercentage = createSelector(
  selectYouthDiscountAges,
  getYouthDiscountPercentageFromAges,
);

export default selectCalculatedYouthDiscountPercentage;

export type YouthDiscountPercentageType = number;
