import { Clickable, Image } from '@ahmdigital/ui';
import React from 'react';

import requestCallImage from './images/request-call.svg';
import styles from './styles.module.sass';

type RequestACallMinimizedProps = {
  actions: {
    requestACall: {
      expand: (...args: unknown[]) => unknown;
    };
  };
};

const RequestACallMinimized = ({ actions }: RequestACallMinimizedProps) => (
  <Clickable isUnstyled onClick={actions.requestACall.expand} variant={styles.minimizedButton}>
    <Image src={requestCallImage.src} className={styles.image} alt="speech bubble that says 'get a call back'" />
    <div className={styles.visuallyHidden}>Get a call back</div>
  </Clickable>
);

export default RequestACallMinimized;
