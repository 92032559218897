import { compact, flow, map, omit } from 'lodash/fp';
import getOpeningHours from '@ahmdigital/logic/lib/utils/get-opening-hours';
import React from 'react';

import { FooterStaticProps } from '../../types';
import constants from '../../../../ahm-constants';
import getStaticProps from '../get-static-props';
import replaceLink from '../replace-link';

const CAR_HOME_PATHS = {
  CAR: 'car-insurance',
  CAR_AND_HOME: 'car-and-home-insurance',
  HOME: 'home-insurance',
};

const FORM_LINKS = {
  [CAR_HOME_PATHS.CAR]: constants.URLS.HELP.CAR_INSURANCE_FORM,
  [CAR_HOME_PATHS.HOME]: constants.URLS.HELP.HOME_INSURANCE_FORM,
};

const getCarHomeInsuranceProps = ({ basePath }: { basePath: string }): FooterStaticProps => {
  const cloneProps = omit('appCalloutIcons')(getStaticProps());

  cloneProps.navLinks = flow(
    map((link: { name: string; linkTo: string }) => {
      const linkTo = replaceLink({
        basePath,
        formLinks: FORM_LINKS,
        link,
      });

      if (!linkTo) {
        return null;
      }

      return {
        ...link,
        linkTo,
      };
    }),
    compact,
  )(cloneProps.navLinks);

  const isHomeInsurance = basePath === CAR_HOME_PATHS.HOME;
  const isCarOrHomeInsurance = basePath !== CAR_HOME_PATHS.CAR_AND_HOME;
  const contacts = compact([
    isCarOrHomeInsurance
      ? {
          contactHours: getOpeningHours(constants.AHM_SERVICE_NAME.CAR_AND_HOME),
          contactNumber: isHomeInsurance
            ? constants.CONTACT.PHONE.HOME_INSURANCE
            : constants.CONTACT.PHONE.CAR_INSURANCE,
          contactNumberPrefix: `${isHomeInsurance ? 'Home' : 'Car'} insurance`,
        }
      : null,
    {
      contactHours: (
        <>
          24 hours a day, 7 days a week
          <br />
          Within Australia and overseas
        </>
      ),
      contactNumber: isHomeInsurance
        ? constants.CONTACT.PHONE.HOME_INSURANCE_EMERGENCY
        : constants.CONTACT.PHONE.CAR_INSURANCE_EMERGENCY,
      contactNumberPrefix: 'For emergency assistance call',
    },
  ]);

  return {
    ...cloneProps,
    contacts,
  } as FooterStaticProps;
};

export default getCarHomeInsuranceProps;
export { CAR_HOME_PATHS };
