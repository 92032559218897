import { compact, head, split } from 'lodash/fp';

const formatUrlPath = ({ pathname }: { pathname?: string } = {}) => {
  if (!pathname) {
    return null;
  }

  return head(compact(split('/')(pathname)));
};

export default formatUrlPath;
