import { get, includes, isBoolean, isEmpty, keys, omitBy } from 'lodash/fp';

import constants from '../../ahm-constants';
import logging from '../../logging';

const lid = Math.round(Math.random() * 1000000);

type LoggingData = {
  incomeTier?: string;
  marketingOptIn?: boolean;
  medicareNumber?: string;
  scale?: string;
  preparedData?: {};
  rawData?: {};
  toPrepare?: {};
};

const formLogging = ({
  data = {},
  identifier,
  isOptionalMedicare = false,
  level,
}: {
  data: LoggingData;
  identifier: string;
  isOptionalMedicare?: boolean;
  level: string;
}) => {
  try {
    const logger = logging.getLogger();
    const toLog = {
      filledKeys: keys(omitBy(isEmpty, data)),
      incomeTier: data.incomeTier,
      lid,
      marketingOptIn: data.marketingOptIn,
      marketingOptInFromMember0: get('members[0].marketingOptIn', data),
      scale: data.scale,
    };
    if (!includes(data.scale, constants.SCALE)) {
      logger[level](`BUYFORM: ${identifier} missing valid scale`, toLog);
    }
    if (!includes(data.incomeTier, constants.INCOME_TIER)) {
      logger[level](`BUYFORM: ${identifier} missing valid incomeTier`, toLog);
    }

    if (!isOptionalMedicare && isEmpty(get('members[0].medicare', data)) && isEmpty(data.medicareNumber)) {
      logger[level](`BUYFORM: ${identifier} missing valid medicare`, toLog);
    }

    if (!isBoolean(get('members[0].marketingOptIn', data)) && !isBoolean(data.marketingOptIn)) {
      logger[level](`BUYFORM: ${identifier} missing valid marketingOptIn`, toLog);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default formLogging;
