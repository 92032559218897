import constants from '../../ahm-constants';

/**
 * Whether the customer can choose their income type based on their scale.
 *
 * Around 45% of single-parents choose a single income type even though they are deemed to be a family, this
 * potentially means a higher premium, but a windfall at tax time.
 * Less than 1% of couples & families choose a single income type, whilst this is allowed it's the business decision
 * to prevent non-singles from choosing a single income type. However, this could lead to them being charged a lower
 * premium but have to pay back to the ATO at tax time.
 *
 * This function is used to disable income type options in the UI such as refine a price drawer and buy form.
 *
 * @param {string} scale - The scale of the customer.
 */
const canChooseIncomeType = (scale: string): boolean => scale === constants.SCALE.SINGLE;

export default canChooseIncomeType;
