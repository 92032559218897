import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    description: (
      <>
        <P>All your bronze category cover, with these important services added-on:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Back, neck and spine</Strong>
          </li>
          <li>
            <Strong>Dental surgery</Strong>
          </li>
          <li>
            <Strong>Lung and chest</Strong>
          </li>
        </List>
        <P>
          You’ll also get <Strong>accident override, unlimited medically necessary ambulance^</Strong> and{' '}
          <Strong>no excess for dependants</Strong>.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
};
