import { get, head, isArray } from 'lodash/fp';
import { useQuery } from '@apollo/client';

import getIsServer from '../../../utils/get-is-server';
import getTableOfContentsListByIdentifierQuery from '../../../models/table-of-contents/utils/get-table-of-contents-list-by-identifier-query';
import logger from '../../../logging';
import uniqueContentWithDraftPrecedence from '../unique-content-with-draft-precedence';

type UseTableOfContents = {
  fragment: string;
  skip: boolean;
  slug: string;
};

const useTableOfContents = ({ slug, fragment, skip }: UseTableOfContents) => {
  const query = getTableOfContentsListByIdentifierQuery(fragment);
  const { data, error, loading } = useQuery(query, {
    skip,
    variables: {
      identifier: slug,
    },
  });

  if (!loading && error) {
    logger.getLogger().error('Failed to get table of contents from CMS', { error, isServer: getIsServer(), slug });
  }

  const items = get('items', head(uniqueContentWithDraftPrecedence(get('allTableOfContentsList', data))));

  return {
    error,
    items: isArray(items) ? items : [],
    loading,
  };
};

export default useTableOfContents;
