import { compact } from 'lodash/fp';
import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../../ahm-constants';

const upgrade = {
  cta: 'Check out value extras',
  id: constants.PRODUCT.VALUE_EXTRAS,
  title: 'Looking for no waiting periods on included extras?',
};
export default {
  description: (
    <P>
      You also get <Strong>50%</Strong> back up to annual limits for the basics: <Strong>routine dental</Strong>,{' '}
      <Strong>physio</Strong>, <Strong>chiro</Strong> and <Strong>osteo</Strong>.
    </P>
  ),
  getSellingPoints: () =>
    compact([
      {
        description: 'up to your annual limit.',
        icon: 'black-50',
        title: '50% back on included extras',
      },
      {
        description: 'You choose extras providers — not us.',
        icon: 'providers',
        title: 'See who you like',
      },
      {
        description: 'TAS and QLD residents are covered by their state schemes.',
        icon: 'ambulance-services',
        title: 'Emergency ambulance included',
      },
    ]),
  spotlight: {
    description: '50% back',
    icon: 'black-50',
  },
  upgrade,
  upsell: {
    description: 'That’s why people add Hospital cover – it can ease hip pocket discomfort if you have an accident.',
    header: 'Hospitals are a pain',
  },
};
