import { GRAPHQL } from '../../../cms/constants';
import { PERK_QUERY } from '../../../cms/queries';

const PERKS = `
  ... on MemberPerk {
    ${PERK_QUERY}
  }
`;

const PERKS_FOR_OFFER_PAGE = `
  ... on MemberPerk {
    slogan
    name
    ${GRAPHQL.IMAGE_OBJECT}
    ${GRAPHQL.RELEASE}
    ${GRAPHQL.URL_OBJECT}
  }
`;

const ANNOUNCEMENTS = `
  ... on BrandCampaign {
    ${GRAPHQL.ID}
    ${GRAPHQL.TYPE}
    name
    sloganRaw
    descriptionRaw
    ${GRAPHQL.IMAGE_OBJECT}
    ${GRAPHQL.URL_OBJECT}
  }
  ... on ComplianceIncident {
    ${GRAPHQL.ID}
    ${GRAPHQL.TYPE}
    name
    descriptionRaw
    ${GRAPHQL.IMAGE_OBJECT}
    ${GRAPHQL.URL_OBJECT}
  }
  ... on ExplainedTerm {
    detailsTeaser
    ${GRAPHQL.SLUG}
    ${GRAPHQL.ID}
    sloganRaw
    summaryRaw
    ${GRAPHQL.IMAGE_OBJECT}
    ${GRAPHQL.URL_OBJECT}
    standAloneInformation
  }
  ... on Legislation {
    ${GRAPHQL.ID}
    ${GRAPHQL.TYPE}
    name
    descriptionRaw
    ${GRAPHQL.IMAGE_OBJECT}
    ${GRAPHQL.URL_OBJECT}
  }
  ... on UnmodelledAnnouncement {
    ${GRAPHQL.ID}
    ${GRAPHQL.TYPE}
    name
    sloganRaw
    descriptionRaw
    ${GRAPHQL.IMAGE_OBJECT}
    ${GRAPHQL.URL_OBJECT}
  }
  ... on ServiceChannel {
    ${GRAPHQL.ID}
    ${GRAPHQL.TYPE}
    name
    sloganRaw
    descriptionRaw
    ${GRAPHQL.IMAGE_OBJECT}
    installUrlObjects {
      name
      url
      ${GRAPHQL.IMAGE}
    }
  }
`;

const WHY_AHM = `
  ... on ValuePropositionGroup {
    ${GRAPHQL.ID}
    ${GRAPHQL.TYPE}
    description: descriptionRaw
    name: title
    valuePropositionsReference: valuePropositions {
      name
      slogan: sloganRaw
      summary: summaryRaw
      ${GRAPHQL.URL_OBJECT}
      ${GRAPHQL.IMAGE_OBJECT}
    }
  }
`;

const CONTACTS = `
  ... on Contact {
    action
    descriptionRaw
    name
    phoneNumber
    title
    type
  }
`;

export const FRAGMENTS = {
  ANNOUNCEMENTS,
  CONTACTS,
  PERKS,
  PERKS_FOR_OFFER_PAGE,
  WHY_AHM,
};

export const SLUG = {
  CURRENT_MEMBER_CONTACTS: 'current-member-contacts',
  LOOKING_TO_JOIN_CONTACTS: 'looking-to-join-contacts',
};
