import { gql } from '@apollo/client';

import { GRAPHQL } from '../../../../cms/constants';

const getTableOfContentsListByIdentifierQuery = (itemFragment: string) => gql`
    query GetTableOfContentsList($identifier: String!) {
      allTableOfContentsList(where: { identifier: { current: { eq: $identifier } } }) {
        name
        items {
          ... on TableOfContentsItem {
            name
            item {
              ${itemFragment}
            }
            ${GRAPHQL.RELEASE}
          }
        }
      }
    }
  `;

export default getTableOfContentsListByIdentifierQuery;
