import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../../ahm-constants';

const upgrade = {
  cta: 'Check out black 70 boost',
  id: constants.PRODUCT.BLACK_70_BOOST_EXTRAS,
  title: 'Looking for more services?',
};

export default {
  description: (
    <P>
      Plus you get <Strong>70%</Strong> back on <Strong>dental</Strong> as well as therapies such as{' '}
      <Strong>physio</Strong> and <Strong>remedial massage</Strong>, and <Strong>100%</Strong> back on{' '}
      <Strong>optical</Strong> up to your annual limit.
    </P>
  ),
  getSellingPoints: () => [
    {
      description: 'up to your annual limit.',
      icon: 'black-70',
      title: '70% back on included extras',
    },
    {
      description: 'Frames, prescription lenses and contact lenses included.',
      icon: 'optical',
      title: '100% back on optical',
    },
    {
      description: 'Available at select dentists.',
      icon: 'no-gap-dental',
      title: 'No gap dental check-ups',
    },
  ],
  spotlight: {
    description: '70% back',
    icon: 'black-70',
  },
  upgrade,
  upsell: {
    description: 'Add hospital cover to your extras and you’ll also add peace of mind.',
    header: 'Make a nasty surprise less nasty',
  },
};
