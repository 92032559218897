import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';
import constants from '../../../ahm-constants';

export default {
  intro: {
    description: (
      <>
        <P>
          Step up to all-silver services with a <Strong>$250 optical limit</Strong> and a flexi extras limit that
          rewards your loyalty, and these hospital benefits
        </P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Heart and vascular</Strong>
          </li>
          <li>
            <Strong>Back, neck and spine</Strong>
          </li>
          <li>
            <Strong>Plastic and reconstructive surgery (medically necessary)</Strong>
          </li>
        </List>
        <P>
          <Strong>Palliative care</Strong> and <Strong>rehab</Strong> also included. You also get{' '}
          <Strong>unlimited medically necessary ambulance^, accident override</Strong> and{' '}
          <Strong>no excess for dependants</Strong>. With the flexibility of an extras limit that goes up every year for
          5 financial years (and could get you up to $1,300 in year 5) on extras like{' '}
          <Strong>dental, physio, chiro and osteo</Strong> - it’s silver with a step-up.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
  upgrade: {
    cta: 'Check out deluxe flexi silver plus',
    id: constants.PRODUCT.DELUXE_FLEXI_SILVER_PLUS_EXCESS_500,
    title: 'Want a little more and a bigger extras limit?',
  },
};
