import getOpeningHours from '@ahmdigital/logic/lib/utils/get-opening-hours';

import constants from '../../ahm-constants';

const getTravelInsuranceConstants = () => ({
  CONTACT: {
    CALL_CENTRE_HOURS: {
      MON_FRI: getOpeningHours(constants.AHM_SERVICE_NAME.TRAVEL_INSURANCE),
      SAT: '9am – 4pm Saturday',
      SUN: '10am – 3pm Sunday',
    },
    EMAIL: 'ahm@travelinsurancepartners.com.au',
    EMERGENCY: [
      {
        COUNTRY: 'New Zealand',
        PHONE: '0800 931 760',
      },
      {
        COUNTRY: 'UK',
        PHONE: '0808 234 2686',
      },
      {
        COUNTRY: 'USA',
        PHONE: '1844 210 9967',
      },
      {
        COUNTRY: 'Canada',
        PHONE: '1844 210 9967',
      },
    ],
    PHONE: {
      PRETTY: '1300 617 409',
      STANDARD: '1300617409',
    },
  },
});

export default getTravelInsuranceConstants;
