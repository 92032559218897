import { get, getOr } from 'lodash/fp';
import cookie from 'cookie';

import browserUtil from './browser';

/**
 * Gets a member number if it exists.
 *
 * @returns {string} - Membership ID.
 */
const getOmsMemberNumber = () => {
  const { _mem } = cookie.parse(getOr('', 'cookie', browserUtil.getDocument()));
  const parsedMem = _mem ? JSON.parse(_mem) : '';
  return get('health.id', parsedMem);
};

export default getOmsMemberNumber;
