import { compact } from 'lodash/fp';
import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../../ahm-constants';

export default {
  description: (
    <P>
      Plus you get <Strong>60%</Strong> back on <Strong>dental</Strong> and therapies such as <Strong>physio</Strong>{' '}
      and <Strong>remedial massage</Strong>, plus <Strong>100%</Strong> back on <Strong>optical</Strong>, up to annual
      limits.
    </P>
  ),
  getSellingPoints: () =>
    compact([
      {
        description: 'up to your annual limit.',
        icon: 'black-60',
        title: '60% back on included extras',
      },
      {
        description: 'Frames, prescription lenses and contact lenses included.',
        icon: 'optical',
        title: '100% back on optical',
      },
      {
        description: 'TAS and QLD residents are covered by their state schemes.',
        icon: 'ambulance-services',
        title: 'Emergency ambulance included',
      },
    ]),
  spotlight: {
    description: '60% back',
    icon: 'black-60',
  },
  upgrade: {
    cta: 'Check out black 70',
    id: constants.PRODUCT.BLACK_70_EXTRAS,
    title: 'Looking for a higher percentage benefit?',
  },
  upsell: {
    description: 'Add hospital cover to your extras and you’ll also add peace of mind.',
    header: 'Make a nasty surprise less nasty',
  },
};
