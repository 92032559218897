import { isNil, isUndefined } from 'lodash/fp';

import constants from '../../../../ahm-constants';
import getOmsMemberNumber from '../../../../utils/get-oms-member-number';

const {
  URLS: { SALES },
} = constants;

const useRequestACall = (pathname: string, lastViewedProduct?: string | null) => {
  const isMember = !isUndefined(getOmsMemberNumber());

  switch (pathname) {
    case SALES.HOME:
      return !isMember && !isNil(lastViewedProduct);
    case SALES.HEALTH_INSURANCE:
      return false;
    case SALES.BUY_SUCCESS:
      return true;
    default:
      return !isMember;
  }
};

export default useRequestACall;
