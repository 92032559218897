import urls from '@ahmdigital/urls';

import config from '../../config';

export const URLS = urls({
  files: {
    base: `${config.get('static:url')}/files`,
  },
  members: {
    base: config.get('members:url'),
  },
  sales: {
    base: '',
  },
});
