/* eslint-disable sort-keys */
import { ANALYTICS } from '@ahmdigital/constants';
import { values } from 'lodash/fp';

const {
  EVENTS: { ECOMMERCE, ECOMMERCE_GA4 },
} = ANALYTICS;

export const EVENTS = {
  ECOMMERCE: {
    ADD_IMPRESSION: ECOMMERCE.ADD_IMPRESSION,
    ADD_PRODUCT: ECOMMERCE.ADD_PRODUCT,
    SET_ACTION: ECOMMERCE.SET_ACTION,
  },
  ECOMMERCE_GA4: {
    VIEW_ITEM_LIST: ECOMMERCE_GA4.VIEW_ITEM_LIST,
    VIEW_ITEM: ECOMMERCE_GA4.VIEW_ITEM,
    ADD_TO_CART: ECOMMERCE_GA4.ADD_TO_CART,
    GENERATE_LEAD: ECOMMERCE_GA4.GENERATE_LEAD,
    BEGIN_CHECKOUT: ECOMMERCE_GA4.BEGIN_CHECKOUT,
    REVIEW_AGR: ECOMMERCE_GA4.REVIEW_AGR,
    ADD_PAYMENT_INFO: ECOMMERCE_GA4.ADD_PAYMENT_INFO,
    PURCHASE: ECOMMERCE_GA4.PURCHASE,
  },
};

export const VALID_EVENTS_ECOMMERCE = values(EVENTS.ECOMMERCE);

export const VALID_EVENTS_ECOMMERCE_GA4 = values(EVENTS.ECOMMERCE_GA4);

// List of dimensions used for google analytics
export const DIMENSIONS = {
  ipAddress: '1',

  // Product
  hospitalTier: '5',
  productId: '7',
  productName: '8',
  productType: '9',

  // Customer
  ageBracket: '10',
  frequency: '11',
  incomeTier: '12',
  incomeType: '13',
  rebate: '14',
  scale: '15',
  state: '16',
  switching: '17',
  income: '18',
  paymentMethod: '54',

  // Promo Codes
  promoCode0: '24',
  promoCode1: '26',
  promoCode2: '27',
  promoCode3: '28',

  // Member
  membershipNewOrExisting: '21',
  membershipId: '29',
  clientId: '31',

  // Liveperson
  agentId: '32',
  campaignId: '33',
  conversationId: '34',
  engagementId: '35',
  visitorId: '36',
  skillName: '37',
};

export const USER_TYPE = {
  member: 'Existing Customer',
  prospect: 'New Customer',
};
