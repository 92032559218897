import { Clickable } from '@ahmdigital/ui';
import React from 'react';

import {
  REQUEST_A_CALL_FAIL,
  REQUEST_A_CALL_SUCCESS,
  SAVE_AND_RETRIEVE_QUOTE_FAIL,
  SAVE_AND_RETRIEVE_QUOTE_SUCCESS,
} from '../../actions/action-types';
import constants from '../../ahm-constants';
import type { LeadType } from '../../components/lead-capture-form/types';

export const LEAD_MESSAGES = {
  NEED_HELP: {
    message: 'Leave your details below and one of our insurance experts will get back to you as soon as possible.',
    title: 'Need help?',
  },
  REQUEST_A_CALL: {
    message: 'We will call you back as soon as possible.',
    requiredFieldsMessage: 'All fields marked with * are required',
    submitButtonText: 'Call me back',
    title: 'Request a call',
  },
  SAVE_AND_RETRIEVE_QUOTE: {
    message: (
      <>
        Enter your details below to get a copy of this cover option via email so you can continue where you left off at
        any time! Your information will be handled in accordance with our{' '}
        <Clickable to={constants.URLS.SALES.PRIVACY} inNewTab>
          privacy policy
        </Clickable>
        .
      </>
    ),
    submitButtonText: 'Email this cover',
    title: 'Email me this cover',
  },
};

export const LEAD_TYPES = {
  ABANDONED_CART_PRE_BUY_FORM: { component: 'preBuyForm' } as LeadType,
  REQUEST_A_CALL: {
    component: 'requestACall',
    failAction: REQUEST_A_CALL_FAIL,
    successAction: REQUEST_A_CALL_SUCCESS,
  } as LeadType,
  SAVE_AND_RETRIEVE_QUOTE: {
    component: 'saveAndRetrieveQuote',
    failAction: SAVE_AND_RETRIEVE_QUOTE_FAIL,
    successAction: SAVE_AND_RETRIEVE_QUOTE_SUCCESS,
  } as LeadType,
};
