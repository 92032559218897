import { find, getOr } from 'lodash/fp';

import constants from '../../ahm-constants';
import getIncomeTierOptions from '../get-income-tier-options';

const getIncomeBracket = (incomeTier: string, rebate: boolean, incomeType: string) => {
  if (rebate) {
    // Any income type other than Single (yes, even Single Parent) uses the income tiers defined for Couple.
    const optionsIncomeType = incomeType === constants.SCALE.SINGLE ? incomeType : constants.SCALE.COUPLE;
    const incomeTierOptions = getIncomeTierOptions({ incomeType: optionsIncomeType });
    return getOr(null, 'text', find({ value: incomeTier }, incomeTierOptions));
  }
  return 'I don’t want to claim the rebate';
};

export default getIncomeBracket;
