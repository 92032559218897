import { get } from 'lodash/fp';

import ahmConstants from '../../../../ahm-constants';

const replaceLink = ({
  link,
  basePath,
  formLinks,
}: {
  formLinks: {
    [key: string]: string;
  };
  basePath: string;
  link: {
    name: string;
    linkTo: string;
  };
}): string => {
  if (link.name === 'Privacy policy') {
    return ahmConstants.URLS.SALES.CAR_AND_HOME_INSURANCE_PRIVACY_POLICY;
  }
  if (link.name === 'Forms and guides') {
    return get(basePath)(formLinks);
  }

  return link.linkTo;
};

export default replaceLink;
