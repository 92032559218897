import { get } from 'lodash/fp';

import constants from '../../../../ahm-constants';

const {
  URLS: {
    SALES: { CHOOSABLE_PICKER },
  },
} = constants;

const URL_TO_CLASSNAME_MAP = {
  [CHOOSABLE_PICKER]: 'choosablePicker',
};

const getAdjustmentClassname = (pathname: string): string | undefined => get(pathname, URL_TO_CLASSNAME_MAP);

export default getAdjustmentClassname;
