import { CLOSE_DRAWER, OPEN_DRAWER } from '../../actions/action-types';

const initialState = {
  openDrawerId: null,
};

export default function drawerReducer(state = initialState, action = {}) {
  const reducers = {
    [CLOSE_DRAWER]: () => ({
      ...state,
      openDrawerId: null,
    }),

    [OPEN_DRAWER]: () => ({
      ...state,
      // @ts-expect-error - Automatic, Please fix when editing this file
      openDrawerId: action.id,
    }),
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type as keyof typeof reducers]() : state;
}
