const LIFE_INSURANCE = {
  GREENSTONE: {
    CONTACT: {
      EMAIL: 'service@life.ahm.com.au',
      PHONE: {
        CLAIMS: {
          PRETTY: '1300 052 589',
          STANDARD: '1300052589',
        },
        SALES: {
          PRETTY: '1300 052 593',
          STANDARD: '1300052593',
        },
      },
    },
  },
  NEOS: {
    CONTACT: {
      EMAIL: 'service@ahmlife.com.au',
      PHONE: {
        CLAIMS: {
          PRETTY: '1300 867 125',
          STANDARD: '1300867125',
        },
        SALES: {
          PRETTY: '1300 508 940',
          STANDARD: '1300508940',
        },
      },
    },
  },
};

export { LIFE_INSURANCE };
