import { contains } from 'lodash/fp';
import getOpeningHours from '@ahmdigital/logic/lib/utils/get-opening-hours';

import { FooterStaticProps } from '../../types';
import constants from '../../../../ahm-constants';
import getStaticProps from '../get-static-props';

const { UI, URLS } = constants;

const EXCLUDED_CONTACT_PATHS = [URLS.SALES.HOME, URLS.SALES.CONTACT_US];

const getDefaultProps = (pathname = null): FooterStaticProps => {
  const cloneProps = getStaticProps();
  // Temporary change to add the phone number back into the default footer on pages other
  // than the black-listed pages
  const contacts = contains(pathname, EXCLUDED_CONTACT_PATHS)
    ? []
    : [{ contactHours: getOpeningHours(constants.AHM_SERVICE_NAME.SALES), contactNumber: UI.CONTACT.PHONE.PRETTY }];

  return {
    ...cloneProps,
    contacts,
    navLinks: [
      {
        linkTo: URLS.SALES.ABOUT,
        name: 'About us',
      },
      {
        linkTo: URLS.SALES.CONTACT_US,
        name: 'Contact us',
      },
      {
        linkTo: URLS.SALES.DOWNLOADS,
        name: 'Forms and guides',
      },
      {
        linkTo: URLS.THIRD_PARTY.CAREERS,
        name: 'Careers',
      },
      {
        linkTo: URLS.SALES.SITEMAP,
        name: 'Site map',
      },
      {
        linkTo: URLS.SALES.TERMS_AND_CONDITIONS,
        name: 'Terms and conditions',
      },
      {
        linkTo: URLS.SALES.PRIVACY,
        name: 'Privacy policy',
      },
    ],
  };
};

export default getDefaultProps;
