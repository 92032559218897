import { compact } from 'lodash/fp';
import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

export default {
  description: (
    <P>
      You also get <Strong>50%</Strong> back on <Strong>dental</Strong> up to your annual limits.
    </P>
  ),
  getSellingPoints: () =>
    compact([
      {
        description: 'up to your annual limit.',
        icon: '',
        title: '50% back on included extras',
      },
      {
        description: 'Use all of your limit on one type of service in the bundle, or spread it across a few.',
        icon: '',
        title: 'Annual bundle limits',
      },
      {
        description: 'Available at select dentists.',
        icon: '',
        title: 'No gap dental check-ups',
      },
    ]),
};
