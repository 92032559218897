const getStickyHeaderHeight = (): string | undefined => {
  const element = document.getElementById('sticky-header');
  if (!element) {
    return undefined;
  }
  const height = element.offsetHeight;
  return String(height);
};

export default getStickyHeaderHeight;
