import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_LEADS = gql`
  mutation createLeadMutation($input: createLeadInput) {
    createLead(input: $input) {
      id
    }
  }
`;
