import { includes, omit, reject } from 'lodash/fp';
import getOpeningHours from '@ahmdigital/logic/lib/utils/get-opening-hours';
import React from 'react';

import { FooterStaticProps, NavLink } from '../../types';
import { TRAVEL_WEEKEND_CONTACT_HOURS } from '../../../../ahm-constants/ui';
import constants from '../../../../ahm-constants';
import getStaticProps from '../get-static-props';
import getTravelInsuranceConstants from '../../../../utils/get-travel-insurance-constants';

const getTravelInsuranceProps = (): FooterStaticProps => {
  const { CONTACT } = getTravelInsuranceConstants();
  const cloneProps = omit('appCalloutIcons')(getStaticProps());

  cloneProps.navLinks = reject((link: NavLink) => includes('Forms and guides')(link))(cloneProps.navLinks);

  const weekdayContactHours = getOpeningHours(constants.AHM_SERVICE_NAME.TRAVEL_INSURANCE);

  const contactHours = (
    <>
      {weekdayContactHours}
      <br />
      {TRAVEL_WEEKEND_CONTACT_HOURS.SATURDAY}
      <br />
      {TRAVEL_WEEKEND_CONTACT_HOURS.SUNDAY}
    </>
  );

  return {
    ...cloneProps,
    contacts: [{ contactHours, contactNumber: CONTACT.PHONE.PRETTY }],
  } as FooterStaticProps;
};

export default getTravelInsuranceProps;
