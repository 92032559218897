import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Footer } from '@ahmdigital/ui';
import { useRouter } from 'next/router';
import React from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';

import determineFooterProps from './utils/determine-footer-props';
import footerActionCreators from '../../actions/footer';

type FooterProps = {
  actions: {
    footer: {
      setVisibility: (isVisible: any) => void;
    };
  };
};

const FooterWithStaticProps = ({ actions }: FooterProps) => {
  const { asPath } = useRouter();
  const props = determineFooterProps(asPath);

  return (
    <ReactVisibilitySensor
      intervalCheck={false}
      onChange={(value) => actions.footer.setVisibility(value)}
      partialVisibility
      scrollCheck
      scrollDelay={50}
    >
      <Footer {...props} />
    </ReactVisibilitySensor>
  );
};

const mapDispatchToProps = (dispatch?: any) => ({
  actions: {
    footer: bindActionCreators(footerActionCreators, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(FooterWithStaticProps);

export { FooterWithStaticProps, mapDispatchToProps };
