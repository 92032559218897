import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

export default {
  description: (
    <P>
      You also get the biggest bunch of extras we’ve got. Plus unlimited <Strong>routine dental</Strong> to keep you and
      your teeth happy.
    </P>
  ),
  getSellingPoints: () => [
    {
      description: 'Get on a first-name basis with your dentist (if you want to!)',
      icon: 'routine-dental',
      title: 'Unlimited routine dental',
    },
    {
      description: 'on included extras.',
      icon: 'dont-wait',
      title: 'No 2 or 6 month waiting periods',
    },
    {
      description: 'Available at select dentists.',
      icon: 'no-gap-dental',
      title: 'No gap dental check-ups',
    },
  ],
  spotlight: {
    description: (
      <span>
        Unlimited routine dental
        <sup>+</sup>
      </span>
    ),
    icon: 'routine-dental',
  },
  upsell: {
    header: 'Add hospital for comprehensive cover',
  },
};
