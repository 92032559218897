import { Clickable, Span } from '@ahmdigital/ui';
import { get, startCase, upperCase } from 'lodash/fp';
import { useSelector } from 'react-redux';
import React from 'react';

import { NOW } from '../../cms/constants';
import styles from './styles.module.sass';

const datasetColors = {
  production: styles.production,
  staging: styles.staging,
};

const modeColors = {
  draft: styles.draft,
  published: styles.published,
};

const CMSOverlay = () => {
  const dataset = useSelector((state) => get('cms.dataset')(state));
  const mode = useSelector((state) => get('cms.mode')(state));
  const asAtDate = useSelector((state) => get('cms.asAtDate', state));
  const isNow = asAtDate === NOW;
  const icon = isNow ? 'pencil-box' : 'time';
  return (
    <Clickable icon={{ name: icon }} isUnstyled to="/cms" variant={[styles.container, get(dataset)(datasetColors)]}>
      <Span variant={styles.dataset}>{startCase(dataset)} </Span>
      <Span variant={[styles.mode, get(mode)(modeColors)]}>{upperCase(mode)}</Span>
    </Clickable>
  );
};

export default CMSOverlay;
