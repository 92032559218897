import constants from '../../../ahm-constants';

export const INCOME_TIER_NONE = 'income-tier-none';
export const SCALES_WITH_MULTIPLE_ADULTS = [
  constants.SCALE.COUPLE,
  constants.SCALE.FAMILY,
  constants.SCALE.FAMILY_PLUS_21,
];

export const FIELD_NAMES = {
  AGE: 'age',
  FREQUENCY: 'frequency',
  HOSPITAL_EXCESS: 'hospitalExcess',
  INCOME_TIER: 'incomeTier',
  INCOME_TYPE: 'incomeType',
  PARTNER_AGE: 'partnerAge',
  REBATE: 'rebate',
  SCALE: 'scale',
  STATE: 'state',
} as const;
