import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';
import constants from '../../../ahm-constants';

export default {
  intro: {
    description: (
      <>
        <P>
          Basic hospital and extras for healthy singles and couples, includes <Strong>accident override</Strong> and{' '}
          <Strong>limited medically necessary ambulance^</Strong> just in case.
        </P>
        <P>
          You also get a loyalty extras limit for <Strong>dental, physio, chiro and osteo</Strong> that goes up every
          year for 5 financial years, so totally worth sticking with us.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
  upgrade: {
    cta: 'Check out essentials flexi basic plus',
    id: constants.PRODUCT.ESSENTIALS_FLEXI_BASIC_PLUS_EXCESS_750,
    title: 'Looking for more?',
  },
};
