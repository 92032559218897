import config from '../../config';
import isFeatureEnabled from '../../utils/is-feature-enabled';

/**
 * Premium Change.
 *
 * NOTE: Configuration is now a feature configurable in the app json file.
 *
 * "ENABLED" - Switches on or off premium change functionality.
 *             This means displaying the premium change toggle component and dual
 *             prices on the buy form.
 *
 * "FUTURE_EFFECTIVE_DATE" - Date which the new premium rates will take effect.
 *                           This date is used throughout premium change related copy.
 *
 * "TOGGLE_LABELS" - The labels used in the premium change toggle component dropdown menu. The keys true and false
 *                   map to the value of useFutureEffectiveDate.
 *                   - "true" displaying prices after `futureEffectiveDate`
 *                   - "false" displaying prices prior to `futureEffectiveDate`.
 */
export default {
  ENABLED: isFeatureEnabled('premiumChange'),
  FUTURE_EFFECTIVE_DATE: config.get('features:premiumChange:futureEffectiveDate'),
  TOGGLE_LABELS: {
    false: 'Before',
    true: 'After',
  },
};
