import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    description: (
      <>
        <P>All the silver category cover, with the following hospital benefits added.</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Insulin pumps</Strong>
          </li>
          <li>
            <Strong>Sleep studies</Strong>
          </li>
          <li>
            <Strong>Pain management with device</Strong>
          </li>
        </List>
        <P>
          <Strong>Palliative care</Strong> and <Strong>rehab</Strong> are also included. You also get{' '}
          <Strong>unlimited medically necessary ambulance^, accident override</Strong> and{' '}
          <Strong>no excess for dependants</Strong>. It’s silver and then some.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
};
