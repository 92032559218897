import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    description: (
      <P>
        Just for accidents, just in case. If you’re single or in a couple get <Strong>accident override</Strong> and{' '}
        <Strong>limited medically necessary ambulance^</Strong> to get you back in action.
      </P>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
};
