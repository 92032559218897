import { useEffect } from 'react';

export const DELACON = {
  companyId: '83086',
};

const delaconScript = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = '//vxml4.plavxml.com/sited/ref/ctrk/670-83086';

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });
};

export default delaconScript;
