import { contactUsPageLink } from './types';
import ahmConstants from '../../ahm-constants';

export const INCOME_LIFE_CONTACT_LINKS: contactUsPageLink[] = [
  {
    description: 'Income protection policy holders',
    link: ahmConstants.URLS.SALES.LIFE_INSURANCE_CONTACT_US,
  },
  {
    description: 'Life insurance policy holders',
    link: `${ahmConstants.URLS.SALES.LIFE_INSURANCE}/help`,
  },
];
