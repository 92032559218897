import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    description: (
      <>
        <P>Our top bronze product and best in class. Including bonus services for added peace of mind like:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Back, neck and spine</Strong>
          </li>
          <li>
            <Strong>Lung and chest</Strong>
          </li>
          <li>
            <Strong>Plastic and reconstructive surgery (medically necessary)</Strong>
          </li>
        </List>
        <P>
          You also get <Strong>unlimited medically necessary ambulance^, accident override</Strong> and{' '}
          <Strong>no excess for dependants</Strong>. It’s for those seeking a more comprehensive cover.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
};
