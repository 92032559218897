import config from '../../config';
import logging from '../../logging';

type RequestHeaders = {
  [key: string]: {
    value: string;
  };
};

type LocationResponse = {
  ipAddress: string;
  stateCode: string;
  requestHeaders?: RequestHeaders; // these are only returned in non-prod
  responseType: 'Geo Matched' | 'Defaults' | 'Errored';
};

const responseHandler = (response: Response) => {
  if (!response.ok) {
    throw new Error('Network response was not OK');
  }
  return response.json();
};

const getGeoLocation = async (): Promise<LocationResponse> => {
  try {
    const result = await fetch(config.get('geoService:url')).then(responseHandler);
    return result;
  } catch (error) {
    logging.getLogger().error('Error fetching geo location', error);
    return Promise.resolve({
      ipAddress: 'Unknown',
      responseType: 'Errored',
      stateCode: 'NSW',
    });
  }
};

export default getGeoLocation;
