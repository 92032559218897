import { P, Small, Strong } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../../ahm-constants';

const upgrade = {
  cta: 'Check out black 70 boost',
  id: constants.PRODUCT.BLACK_70_BOOST_EXTRAS,
  title: 'Looking for a percentage back cover?',
};

export default {
  description: (
    <P>
      From <Strong>braces</Strong> to <Strong>orthotics</Strong>, and even <Strong>pre and post natal services</Strong>.
    </P>
  ),
  getSellingPoints: () => [
    {
      description: 'Heaps of dental.',
      icon: 'major-dental',
      title: 'Holy molars?',
    },
    {
      description: 'on included extras.',
      icon: 'dont-wait',
      title: 'No 2 or 6 month waiting periods',
    },
    {
      description: 'Available at select dentists.',
      icon: 'no-gap-dental',
      title: 'No gap dental check-ups',
    },
  ],
  spotlight: {
    description: (
      <>
        No family limits
        <br />
        <Small>(everyone has their own)</Small>
      </>
    ),
    icon: 'no-family-limits',
  },
  upgrade,
  upsell: {
    header: 'Be prepared for growing families',
  },
};
