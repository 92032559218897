import { createSelector } from 'reselect';
import { find, toLower } from 'lodash/fp';

import constants from '../../ahm-constants';
import selectAgeBracket from '../select-age-bracket';

const selectAgeBracketOption = createSelector(selectAgeBracket, (ageBracket) =>
  toLower(find({ value: ageBracket }, constants.REBATE_AGE_BRACKET_OPTIONS).text),
);

export default selectAgeBracketOption;
