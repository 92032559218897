import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import browser from '../../../utils/browser';

const ScriptContext = createContext<{ hasProductReviewLoaded: boolean }>({ hasProductReviewLoaded: false });

type ProductReviewScriptProviderProps = {
  children: ReactNode;
};

const ProductReviewScriptProvider = ({ children }: ProductReviewScriptProviderProps) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const documentObj = browser.getDocument();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const script = documentObj.createElement('script') as HTMLScriptElement;
    script.async = true;
    script.src = 'https://cdn.productreview.com.au/assets/widgets/loader.js';
    script.onload = () => {
      setIsScriptLoaded(true);
    };

    documentObj.body.appendChild(script);
    // eslint-disable-next-line no-underscore-dangle
    browser.getWindow().__productReviewSettings = {
      brandId: '2004a332-ea0f-4a24-bd7f-66513fd58cc8',
    };
    return () => {
      documentObj.body.removeChild(script);
    };
  }, []);

  const contextValue = useMemo(() => ({ hasProductReviewLoaded: isScriptLoaded }), [isScriptLoaded]);

  return <ScriptContext.Provider value={contextValue}>{children}</ScriptContext.Provider>;
};

export default ProductReviewScriptProvider;
export const useHasProductReviewScript = () => useContext(ScriptContext);
