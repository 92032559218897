import canChooseIncomeType from '../../../../utils/can-choose-income-type';
import constants from '../../../../ahm-constants';
import logger from '../../../../logging';
import type { Customer } from '../../types';

// TODO: [AP-501] Remove this function once we are confident that all customers are on the correct scale

/**
 * For customers not on a 'single' scale policy (e.g. 'single-parent' or 'family'), set their incomeType to 'couple'.
 * The UI should prevent this behaviour unless coming in via a direct link e.g. Save and retrieve quote url.
 *
 * @param {Customer} customer - The customer object.
 */
const enforceCustomerHasCorrectIncomeType = (customer: Customer): Customer => {
  if (!canChooseIncomeType(customer.scale) && customer.incomeType === constants.INCOME_TYPE.SINGLE) {
    // We can monitor the logs with the intent of removing this code once we are confident that all customers are on the correct scale as some Save and Retrieve Quote links could still have the old options.
    logger.getLogger().info('enforceCustomerHasCorrectIncomeType: Non-single customer has ”single” income type.', {
      incomeType: customer.incomeType,
      scale: customer.scale,
    });
    return {
      ...customer,
      incomeType: constants.INCOME_TYPE.COUPLE,
    };
  }

  return customer;
};

export default enforceCustomerHasCorrectIncomeType;
