import { createSelector } from 'reselect';
import { get, isNil } from 'lodash/fp';

import selectCustomer from '../select-customer';
import selectProducts from '../select-products';

const selectLastViewedProductId = createSelector([selectCustomer, selectProducts], (customer) => {
  const lastViewedProductId = get('lastViewedProduct', customer);

  if (isNil(lastViewedProductId)) {
    return null;
  }

  return lastViewedProductId;
});

export default selectLastViewedProductId;
