import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../../ahm-constants';

const upgrade = {
  cta: 'Check out lifestyle extras',
  id: constants.PRODUCT.LIFESTYLE_EXTRAS,
  title: 'Looking for more services?',
};

export default {
  description: (
    <P>
      You also get a broad range of services including <Strong>dental</Strong>, <Strong>psychology</Strong>,{' '}
      <Strong>counselling</Strong>, <Strong>physio</Strong>, <Strong>chiro</Strong>, plus <Strong>100%</Strong> back on{' '}
      <Strong>optical</Strong> up to your annual limits.
    </P>
  ),
  getSellingPoints: () => [
    {
      description: 'Frames, prescription lenses and contact lenses included.',
      icon: '',
      title: '100% back on optical',
    },
    {
      description: 'Service must be provided by an ahm recognised psychologist or counsellor.',
      icon: '',
      title: 'Psychology and counselling',
    },
    {
      description: 'Combined limit, excludes dentures, partial plates and orthodontics.',
      icon: '',
      title: 'Routine, Major and Complex Dental',
    },
  ],
  upgrade,
};
