import getAppDate from '@ahmdigital/logic/lib/utils/get-app-date';
import getSaleableProductIdsLib from '@ahmdigital/logic/lib/utils/get-saleable-product-ids';

import config from '../../config';

/**
 * A small wrapper utility to save importing config and getAppDate everywhere.
 */
const getSaleableProductIds = () => {
  const appDate = getAppDate(config);
  return getSaleableProductIdsLib(appDate);
};

export default getSaleableProductIds;
