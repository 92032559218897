import { Alert, Clickable, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../ahm-constants';
import styles from './styles.module.sass';

const { URLS } = constants;

export const successHeading = 'Thanks for requesting a call';
const successText = 'You’ll hear from one of our helpful humans soon.';

export const failureHeading = "We're sorry, something went wrong";
const failureText = (
  <>
    Please try again or{' '}
    <Clickable className={styles.contactUs} to={URLS.SALES.CONTACT_US}>
      contact&nbsp;us
    </Clickable>
    .
  </>
);

export const successMessage = (
  <span>
    <Strong>{successHeading}</Strong>
    <br />
    {successText}
  </span>
);

export const failedMessage = (
  <P>
    {failureHeading}. {failureText}
  </P>
);

export const productPageSuccessMessage = (
  <Alert heading={successHeading} level="success">
    {successText}
  </Alert>
);

export const productPageFailedMessage = (
  <Alert heading={failureHeading} level="danger">
    {failureText}
  </Alert>
);
