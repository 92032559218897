import urls from '@ahmdigital/urls/lib/urls';

import finderAward2023Image from './images/finder-award-2023.svg';
import royMorganAward2022Image from './images/roy-morgan-award-2022.svg';

const AWARDS = {
  blurb: 'We are customer satisfaction award winners!',
  callout: {
    text: null,
    to: null,
  },
  images: [
    {
      alt: 'Roy Morgan Award',
      imageSrc: royMorganAward2022Image.src,
      label: 'roy-morgan-award-2022-link',
      to: urls.THIRD_PARTY.ROY_MORGAN_CUSTOMER_SATISFACTION_AWARDS,
    },
    {
      alt: 'Finder Award',
      imageSrc: finderAward2023Image.src,
      label: 'finder-award-2023-link',
      to: urls.THIRD_PARTY.FINDER_CUSTOMER_SATISFACTION_AWARDS,
    },
  ],
};

export default AWARDS;
