import { head, isEmpty } from 'lodash/fp';

import isRequestACallDenylistPage from '../is-request-a-call-denylist-page';

/**
 * Request A Call capture rule = show on non buy flow pages.
 *
 * @param {object} state - Redux state.
 * @returns {boolean} Flag for whether to show Request A Call.
 */
// @ts-expect-error - Automatic, Please fix when editing this file
const getCurrentPage = (state) => !isEmpty(state.history) && !isRequestACallDenylistPage(head(state.history));

export default getCurrentPage;
