import { GENDER, PRODUCT_TYPE, SCALE, STATE, TITLE } from '@ahmdigital/constants';
import { map } from 'lodash/fp';

import { STATE_LABEL } from '../../ahm-constants/ui';

export const GENDER_OPTIONS = [
  { text: 'Male', value: GENDER.MALE },
  { text: 'Female', value: GENDER.FEMALE },
];

export const STATE_OPTIONS = map(
  (state) => ({ text: STATE_LABEL[state], value: state }),
  [STATE.ACT, STATE.NSW, STATE.QLD, STATE.SA, STATE.TAS, STATE.VIC, STATE.WA, STATE.NT],
);

export const SCALE_OPTIONS = [SCALE.SINGLE, SCALE.COUPLE, SCALE.FAMILY, SCALE.SINGLE_PARENT];

export const SCALES_WITH_PARTNER = [SCALE.COUPLE, SCALE.FAMILY, SCALE.FAMILY_PLUS_21];

export const PRODUCT_TYPE_OPTIONS = [
  {
    text: 'hospital and extras packages',
    value: PRODUCT_TYPE.PACKAGE,
  },
  {
    text: `${PRODUCT_TYPE.HOSPITAL} cover`,
    value: PRODUCT_TYPE.HOSPITAL,
  },
  {
    text: `${PRODUCT_TYPE.EXTRAS} cover`,
    value: PRODUCT_TYPE.EXTRAS,
  },
];

export const TITLE_OPTIONS = [
  { text: 'Please select', value: '' },
  { text: 'Mr', value: TITLE.MR },
  { text: 'Mrs', value: TITLE.MRS },
  { text: 'Miss', value: TITLE.MISS },
  { text: 'Ms', value: TITLE.MS },
  { text: 'Mx', value: TITLE.MX },
  { text: 'Dr', value: TITLE.DR },
];
