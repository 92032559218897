import getOpeningHours from '@ahmdigital/logic/lib/utils/get-opening-hours';

import { FooterStaticProps } from '../../types';
import acknowledgementFlagsImage from './images/acknowledgement-flags.png';
import appleAppBadgeImage from '../../../../images/apple-app-badge.svg';
import AWARDS from '../../../../components/awards/data/awards';
import constants from '../../../../ahm-constants';
import googlePlayBadgeImage from '../../../../images/google-play-badge.svg';
import whiteLogoImage from './images/white-logo.svg';

const getStaticProps = (): FooterStaticProps => ({
  acknowledgement: {
    description: `ahm acknowledges Aboriginal and Torres Strait Islander peoples as the First Peoples of this nation. We proudly recognise Elders past, present and emerging as the Traditional Owners of the lands on which we work and live.
    We’re committed to supporting Indigenous self-determination and envision a future where all Australians embrace Aboriginal and Torres Strait Islander histories, cultures and rights as a central part of our national identity.`,
    image: acknowledgementFlagsImage.src,
  },
  appCalloutIcons: {
    android: { image: googlePlayBadgeImage.src, link: constants.URLS.APP_STORE.ANDROID },
    ios: { image: appleAppBadgeImage.src, link: constants.URLS.APP_STORE.IOS },
  },
  awards: AWARDS,
  contacts: [
    {
      contactHours: getOpeningHours(constants.AHM_SERVICE_NAME.SALES),
      contactNumber: '134 246',
    },
  ],
  disclaimer: `© ${new Date().getFullYear()} ahm health insurance is a business of Medibank Private Limited ABN 47 080 890 259. ‘ahm health insurance’ and ‘ahm’ are references to Medibank Private Ltd trading as ahm health insurance.`,
  logo: {
    alt: 'Go to ahm home',
    image: whiteLogoImage.src,
    linkTo: constants.URLS.SALES.HOME,
    title: 'Go to ahm home',
  },
  navLinks: [
    {
      linkTo: constants.URLS.SALES.SITEMAP,
      name: 'Site map',
    },
    {
      linkTo: constants.URLS.SALES.TERMS_AND_CONDITIONS,
      name: 'Terms and conditions',
    },
    {
      linkTo: constants.URLS.SALES.PRIVACY,
      name: 'Privacy policy',
    },
    {
      linkTo: constants.URLS.THIRD_PARTY.CAREERS,
      name: 'Careers',
    },
    {
      linkTo: constants.URLS.SALES.DOWNLOADS,
      name: 'Forms and guides',
    },
    {
      linkTo: constants.URLS.SALES.ABOUT,
      name: 'About us',
    },
  ],
});

export default getStaticProps;
