import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

import AgrService from '../../models/agr';
import selectAgeBracket from '../select-age-bracket';
import selectCustomer from '../select-customer';
import selectRawPrice from '../select-raw-price';

export default createSelector([selectCustomer, selectRawPrice, selectAgeBracket], (customer, rawPrice, ageBracket) =>
  AgrService.getPercentage(
    { ...customer, ageBracket },
    {
      useFutureEffectiveDate: get('useFutureEffectiveDate', rawPrice),
    },
  ),
);
