import { createSelector } from 'reselect';
import { isPlainObject } from 'lodash/fp';

import PriceService from '../../models/price';
import selectCartProductId from '../select-cart-product-id';
import selectCustomer from '../select-customer';
import selectEffectiveDateOverride from '../select-effective-date-override';
import selectPrices from '../select-prices';

const selectCartPrice = createSelector(
  [selectCartProductId, selectCustomer, selectEffectiveDateOverride, selectPrices],
  (cartProductId, customer, effectiveDateOverride, prices) =>
    (isPlainObject(customer) &&
      PriceService.getPrice(prices, cartProductId, customer, { date: effectiveDateOverride })) ||
    null,
);

export default selectCartPrice;
