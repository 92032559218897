import { AuthenticatedFooter } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../ahm-constants';

const FooterPurchase = () => (
  <AuthenticatedFooter
    variant="noBorder"
    links={[
      { linkTo: constants.URLS.SALES.TERMS_AND_CONDITIONS, name: 'Terms' },
      { linkTo: constants.URLS.SALES.PRIVACY, name: 'Privacy' },
      { linkTo: `tel:${constants.CONTACT.PHONE.STANDARD}`, name: `Need help? ${constants.CONTACT.PHONE.PRETTY}` },
    ]}
  />
);

export default FooterPurchase;
