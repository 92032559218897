import { useRouter } from 'next/router';
import React from 'react';

import ahmLogoAlt2XImage from './images/ahm-logo-alt@2x.png';
import constants from '../../ahm-constants';
import defaultFbImage from '../../images/default-fb.jpg';

const StructuredDataBlock = () => {
  const { pathname } = useRouter();

  return (
    pathname === constants.URLS.SALES.HOME && (
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'Corporation',
            areaServed: 'Australia',
            description:
              'Insuring you for the things you’ll actually use. Don’t waste a cent! Smart, affordable health cover. Join online.',
            email: constants.UI.CONTACT.EMAIL.INFO,
            foundingDate: '1971',
            image: defaultFbImage.src,
            knowsAbout: 'Private Health Insurance',
            legalName: 'ahm health insurance',
            logo: ahmLogoAlt2XImage.src,
            name: 'ahm',
            parentOrganization: {
              name: 'Medibank Private Limited',
            },
            sameAs: [
              constants.URLS.SOCIAL.AHM_FACEBOOK,
              constants.URLS.SOCIAL.AHM_TWITTER,
              constants.URLS.SOCIAL.AHM_YOUTUBE,
              constants.URLS.SOCIAL.AHM_INSTAGRAM,
            ],
            telephone: constants.UI.CONTACT.PHONE.PRETTY,
            url: constants.URLS.SALES.HOME,
          }),
        }}
      />
    )
  );
};

export default StructuredDataBlock;
export { StructuredDataBlock as StructuredDataBlockForTest };
