// Forces the scroll to occur
const ScrollToView = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const hashId = window.location.hash;

  if (hashId) {
    // Use the hash to find the first element with that id
    const element = document.querySelector(hashId);

    if (element) {
      // Smooth scroll to that element
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
};

export default ScrollToView;
