import type { Dispatch } from 'redux';

import { LOCATION_LOAD, LOCATION_LOAD_FAIL, LOCATION_LOAD_SUCCESS, STATE_CHANGE_DEFAULT } from '../action-types';
import getGeoLocation from '../../features/get-geo-location';

const load = () => async (dispatch: Dispatch) => {
  dispatch({ type: LOCATION_LOAD });
  await getGeoLocation()
    .then((location) => {
      dispatch({ location, type: LOCATION_LOAD_SUCCESS });
      dispatch({ location, type: STATE_CHANGE_DEFAULT });
    })
    .catch(() => dispatch({ type: LOCATION_LOAD_FAIL }));
};

export default { load };
