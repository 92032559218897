import { P, Strong } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../../ahm-constants';

export default {
  description: (
    <P>
      You also get <Strong>dental</Strong> including <Strong>braces</Strong>, plus <Strong>optical</Strong> and{' '}
      <Strong>laser eye surgery</Strong>. You’ll be loving life with plenty of extras goodness.
    </P>
  ),
  getSellingPoints: () => [
    {
      description: 'Frames, prescription lenses and contact lenses included.',
      icon: 'optical',
      title: '100% back on optical',
    },
    {
      description: 'on included extras.',
      icon: 'dont-wait',
      title: 'No 2 or 6 month waiting periods',
    },
    {
      description: 'Available at select dentists.',
      icon: 'no-gap-dental',
      title: 'No gap dental check-ups',
    },
  ],
  isMostPopular: true,
  spotlight: {
    description: (
      <span>
        100% back on optical
        <sup>#</sup>
      </span>
    ),
    icon: 'optical',
  },
  upgrade: {
    cta: 'Lose the family limits with family extras',
    id: constants.PRODUCT.FAMILY_EXTRAS,
    title: '',
  },
  upsell: {
    header: 'Cover for the things you might need',
  },
};
