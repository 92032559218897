import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';
import constants from '../../../ahm-constants';

export default {
  intro: {
    description: (
      <>
        <P>
          Basic hospital and popular extras, with some sweet bonuses. <br />
          Get treatment for accidents with some rehab, and these added services:
        </P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Dental surgery</Strong>
          </li>
          <li>
            <Strong>Hernia and appendix</Strong>
          </li>
          <li>
            <Strong>Joint reconstructions</Strong>
          </li>
          <li>
            <Strong>Tonsils, adenoids and grommets</Strong>
          </li>
        </List>
        <P>
          Includes <Strong>unlimited medically necessary ambulance^</Strong> and <Strong>accident override</Strong> just{' '}
          in case.
        </P>
        <P>
          You get <Strong>a separate optical limit</Strong>, with a flexi extras limit for things like{' '}
          <Strong>dental, physio, chiro, osteo</Strong> and <Strong>remedial massage</Strong> that goes up every year
          for 5 financial years. Sweet.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
  upgrade: {
    cta: 'Check out lite flexi bronze plus',
    id: constants.PRODUCT.LITE_FLEXI_BRONZE_PLUS_EXCESS_500,
    title: 'Want extra extras?',
  },
};
