import React from 'react';

import constants from '../../../ahm-constants';

const upgrade = {
  cta: 'Check out lifestyle extras',
  id: constants.PRODUCT.LIFESTYLE_EXTRAS,
  title: 'Looking for more services?',
};

export default {
  getSellingPoints: () => [
    {
      description: 'Frames, prescription lenses and contact lenses included.',
      icon: 'optical',
      title: '100% back on optical',
    },
    {
      description: 'on included extras.',
      icon: 'dont-wait',
      title: 'No 2 or 6 month waiting periods',
    },
    {
      description: 'Available at select dentists.',
      icon: 'no-gap-dental',
      title: 'No gap dental check-ups',
    },
  ],
  isMostPopular: true,
  spotlight: {
    description: (
      <span>
        100% back on optical
        <sup>#</sup>
      </span>
    ),
    icon: 'optical',
  },
  upgrade,
};
